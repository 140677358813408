
<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">

          <div class="vx-row no-gutter justify-center items-center">

            <div class="vx-col hidden lg:block lg:w-1/2">
              <img src="@/assets/images/pages/login.png" alt="login" class="mx-auto">
            </div>

            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="p-8 login-tabs-container">

                <div class="vx-card__title mb-4">
                  <h4 class="mb-4">Login</h4>
                  <p>Silahkan login dengan akun anda.</p>
                </div>

                <div>
                  <vs-input
                      data-vv-validate-on="blur"
                      v-validate="'required|min:4'"
                      name="username"
                      icon-no-border
                      icon="icon icon-user"
                      icon-pack="feather"
                      label-placeholder="Username"
                      v-model="username"
                      class="w-full"/>
                    <span class="text-danger text-sm">{{ errors.first('username') }}</span>
                  <vs-input
                      data-vv-validate-on="blur"
                      v-validate="'required|min:4'"
                      type="password"
                      name="password"
                      icon-no-border
                      icon="icon icon-lock"
                      icon-pack="feather"
                      label-placeholder="Password"
                      v-model="password"
                      class="w-full mt-6" />
                    <span class="text-danger text-sm">{{ errors.first('password') }}</span>
                  <div class="flex flex-wrap justify-between my-4 mx-auto">
                      <vs-checkbox v-model="checkbox_remember_me" class="mb-3">Ingat Saya ?</vs-checkbox>
                      <vs-button type="line" @click="passwordLupa = true" class="px-0">Lupa Password</vs-button>
                  </div>
                  <vs-button  :disabled="!validateForm" class="float-right mb-5" @click="login">Login</vs-button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </vx-card>
      <vs-prompt
        color="danger"
        title="Lupa Password"
        @accept="lupa"
        @close="close"
        :is-valid="validateEmail"
        :active.sync="passwordLupa">
        <div class="con-exemple-prompt">
          <vs-input placeholder="Email" name="email" v-validate="'required|email'" v-model="email"/>
          <vs-alert :active="!validateEmail" color="danger" icon="new_releases" >
            Silahkan input email anda
          </vs-alert>
         </div>
       </vs-prompt>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default{

  data() {
    return {
      username: '',
      password: '',
      checkbox_remember_me: false,
      passwordLupa: false,
      email: '',
    }
  },

  mounted() {
    //this.$router.push("/");
  },

  computed: {
    ...mapGetters({
      user  : 'auth/user',
      menu  : 'akses/menu',
      prevUrl : 'prevUrl',
    }),
    validateForm() {
      return !this.errors.any() && this.username !== '' && this.password !== ''
    },
    validateEmail() {
      return !this.errors.any() && this.email !== ''
    }
  },
  methods: {
    ...mapActions({
      setAlert  : 'alert/set',
      setAuth   : 'auth/set',
      setMenu   : 'akses/set',
    }),
    login() {
      if (!this.errors.any()) {
        this.$vs.loading()
        let formData = {
          'username' : this.username,
          'password' : this.password
        }
        this.axios.post('/login', formData)
        .then((response) => {
          this.$vs.loading.close()
          let { data } = response.data
          
          this.setAuth(data.user)
          this.setMenu(data.menu)

          if(this.user.id > 0 && this.user.aktif == 1){

            this.$vs.notify({
              title: response.data.message,
              color  : 'success',
              text  : 'Login Berhasil',
            })
            /*
            if(this.prevUrl.length>0) {
              this.$router.push(this.prevUrl)
            } else {
              this.$router.push('/')
            }
            */
            this.$router.push('/')
            this.close()
          } else {
            this.$vs.notify({
              title: 'Error',
              text: 'Tidak ditemukan atau sudah tidak aktif',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          }
          
        })
        .catch((error) => {
          this.$vs.loading.close()
          let { data } = error.response

          this.$vs.notify({
            title: 'Error',
            text: data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
        
      }
    },
    close() {
      this.$emit('closed', false)
    },
    lupa() {
      if (!this.errors.any()) {
        this.$vs.loading()
        let formData = {
          'email' : this.email
        }

        this.axios.post('/passwordlupa', formData)
        .then((response) => {
          //let { data } = response.data

          this.$vs.notify({
            title: response.message,
            color  : 'success',
            text  : 'Silahkan cek email anda',
          })
          this.$vs.loading.close()

        })
        .catch((error) => {
          this.$vs.loading.close()
          let { data } = error.response

          this.$vs.notify({
            title: 'Error',
            text: data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })

      }
    }
  },
}
</script>
